import {Component, Input, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {FullOffer} from '../../../../models/offer/full-offer';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription, throwError} from 'rxjs';
import {StockApiService} from '../../../../services/stock-api.service';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {BasicOffer} from '../../../../models/offer/basic-offer';
import {CrystalLightbox, LightboxComponent} from 'ngx-crystal-gallery';
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import {QueryStringService} from '../../../../services/query-string.service';
import {DefaultImagePipe} from '../../../../tools/default-image.pipe';
import {ObservedOfferService} from '../../../../services/observed-offer.service';
import {ComparedOfferService} from '../../../../services/compared-offer.service';
import {ShareComponent} from '../../../../modals/share/share.component';
import {AppComponent} from '../../../../app.component';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {AdobeService} from '../../../../services/adobe.service';
import {ErrorHandler} from '../../../../tools/error-handler';
import {BlueKaiService} from '../../../../services/blue-kai.service';
import {TitleService} from '../../../../services/title.service';
import {LabelProviderService} from '../../../../services/labelProvider.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TrackingService} from '../../../../services/tracking.service';
import {environment} from '../../../../../environments/environment';
import {BrandService} from '../../../../services/brand.service';
import {BannersService} from '../../../../services/banners.service';
import {BannerComponent} from '../../../../app-components/banner/banner.component';
import {NewsletterService} from '../../../../services/newsletter.service';
import * as Raven from 'raven-js';
import {
  FinancialCalculatorPluginService
} from '../financial-api-calculator/financial-calculations-js-plugin/financial-calculator-plugin.service';
import { deflateRaw } from 'pako';
import {Calculation} from '../../../../models/offer/financing/financing';
import {SessionStorageService} from '../../../../services/session-storage.service';
import {ConfigModel} from '../../../../config/config';
import {TawkToChatService} from '../../../../services/tawk-to-chat.service';

declare let ga: Function;
declare let gtag: Function;
declare let window: any;
declare var google: any;

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
})
export class OfferComponent implements OnInit, OnDestroy {
  private static STORAGE_SOL_KEY = 'sol-offer';
  static freespeeMasterkeyData = null;
  offer: FullOffer = null;
  index = 0;
  loaded = false;
  galleryImages = [];
  galleryVidImg = [];
  showVin: boolean = false;
  siteData = window.sitedata;
  adobeThankyouPageData = null;
  offerNotExist = false;
  hiddenText = {
    description: true,
    equipmentStandard: true,
    equipmentOptional: true,
    technicalData: false
  };
  appComponentReference = AppComponent;
  lastFindProperties = {};
  financialProductsLabel = [];
  freespeeDealerPhoneNumber = null;
  activeCustomer = 'b2b'; // b2c or b2b
  activeFinancialCalculatorId = {
    b2c: null,
    b2b: null,
  };
  availableFinancialCalculators = {
    b2c: [],
    b2b: [],
  };
  offerFinancialCalculators = {
    b2c: [],
    b2b: [],
  };
  customerTab = false;
  beamHeadline = null;
  modalFormIsOpen = false;
  isSol = false; // selling on-lone
  wasSol = null;
  infoFormUrl = null;
  insuranceSol = null;
  insuranceSolPercent = null;
  ctaType = 'RP';
  packageService = null;
  additionalProtection = false;

  player = {};

  private idParamSubscription: Subscription;

  configThumb: SwiperConfigInterface = {
    loop: false,
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 4,
    preloadImages: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 3,
      loadOnTransitionStart: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    observer: true
  };
  config: SwiperConfigInterface = {
    effect: 'fade',
    followFinger: false,
    loop: false,
    preloadImages: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1,
      loadOnTransitionStart: true
    },
    slidesPerView: 1,
    spaceBetween: 0,
    keyboard: true,
    navigation: false,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
      hideOnClick: false
    },
    observer: true
  };
  similarOffers: Array<BasicOffer>;
  mouseDownPositionX = 0;
  mouseUpPositionX = -1;
  isCalculatorTest = false;

  @Output() currentModel = new EventEmitter<string>();

  brandSlug = '';
  openBox = {
    equipmentStandard: false,
    equipmentOptional: false,
    dealerDescription: true,
  };
  labelClassName = {
    lowestPrice: false,
    highestDiscount: false,
    insurance: false,
  };
  promoAR_oc_1 = false;
  brandConfig: ConfigModel = null;
  showModalFlex = false;
  serviceFlex = false;
  optionalInsuranceForBev = false;
  selectedPriceType = '';
  solCounter = 0;
  solCounterText = '';

  constructor(

    private route: ActivatedRoute,
    private router: Router,
    private stockApi: StockApiService,
    public lightBox: CrystalLightbox,
    public stickyThing: AngularStickyThingsModule,
    public observedOffers: ObservedOfferService,
    public comparedOffers: ComparedOfferService,
    private QSS: QueryStringService,
    private LSS: LocalStorageService,
    private adobeService: AdobeService,
    private blueKaiService: BlueKaiService,
    private titleService: TitleService,
    private labelProviderService: LabelProviderService,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private bannerService: BannersService,
    public newsletterService: NewsletterService,
    private SSS: SessionStorageService
  ) {
    if ($(window).width() < 980) {
      this.hiddenText.technicalData = true;
    }

    $(window).on('resize', () => {
      if ($(window).width() < 980) {
        this.hiddenText.technicalData = true;
        $($('.hidden-box[data-opt="technicalData"]')[0]).removeClass('hiddenMoreLessLinks');
      } else {
        this.hiddenText.technicalData = false;
        $($('.hidden-box[data-opt="technicalData"]')[0]).addClass('hiddenMoreLessLinks');
      }
    });

    this.offer = new FullOffer();
    this.idParamSubscription = this.route.params.subscribe(params => {
      {
        // restore default values
        this.offer = null;
        this.index = 0;
        this.loaded = false;
        this.galleryImages = [];
        this.galleryVidImg = [];
        this.hiddenText = {
          description: true,
          equipmentStandard: true,
          equipmentOptional: true,
          technicalData: false
        };
        if ($(window).width() < 980) {
          this.hiddenText.technicalData = true;
        }
      }
      let dealer_code = null;
      if (this.appComponentReference.isDealerStock) {
        dealer_code = this.appComponentReference.dealerCode;
      }

      this.offerFinancialCalculators = {
        b2c: [],
        b2b: [],
      };

      this.availableFinancialCalculators = {
        b2c: [],
        b2b: [],
      };

      this.activeFinancialCalculatorId = {
        b2c: null,
        b2b: null,
      };

      this.stockApi.getOfferData(+params['id'], dealer_code).then(value => { // (+) converts string 'id' to a number
        FinancialCalculatorPluginService.init();
        value = this.labelProviderService.fillOfferLabels(value);
        this.offer = value;

        if (this.SSS.get('calculator-test') !== null) {
          this.isCalculatorTest = true;
        }

        this.wasSol = null;
        FinancialCalculatorPluginService.addOffer('calculate_' + this.offer.id, this.offer, this.isCalculatorTest);
        FinancialCalculatorPluginService.onUpdate.subscribe((data) => {
          if (this.activeFinancialCalculatorId.b2b === null && this.activeFinancialCalculatorId.b2c === null) {
            if (this.offer.brand.slug === 'jeep' || this.offer.brand.slug === 'alfa-romeo') {
              this.setOrderCalculator(['b2b', 'l101', 'b2c', 'p0p'], data.calculation);
            } else {
              this.setOrderCalculator(['l101', 'b2b', 'p0p', 'b2c'], data.calculation);
            }
          }
        });
        this.showLabelClass();
        if (this.offer.dealer.subdealer) {
          this.initMap(this.offer.dealer.coordinates.latitude, this.offer.dealer.coordinates.longitude);
        }

        if (BrandService.getCurrentBrand().code === '83' && this.offer.price.leasing) {
          AppComponent.addOfferPriceFinancialProductNotes(this.offer.price.leasing.note);
        }

        if (environment.production &&
          BrandService.getCurrentBrand().code !== '00' &&
          BrandService.getCurrentBrand().code !== '77' &&
          BrandService.getCurrentBrand().code !== '83') {
          this.offer.equipment.standard = [];
        }
        this.galleryImages = [];
        this.galleryVidImg = [];

        if (this.offer.dealer !== null) {
          this.titleService.setTitleOffer(this.offer.model.name, this.offer.version.name, this.offer.dealer.name);
        } else {
          this.titleService.setTitleOffer(this.offer.model.name, this.offer.version.name, 'invenduto');
        }
        this.currentModel.emit(this.offer.model.group.slug);

        if (this.offer.adobeConfiguration) {
          this.adobeService.pageLoadOffer(
              this.offer.adobeConfiguration.vehicleTrim === '' ? null : this.offer.adobeConfiguration.vehicleTrim,
              this.offer.adobeConfiguration.vehicleModel === '' ? null : this.offer.adobeConfiguration.vehicleModel,
              this.offer.adobeConfiguration.vehicleID === '' ? null : this.offer.adobeConfiguration.vehicleID,
          );

          this.adobeThankyouPageData = this.adobeService.getThankyouPageOfferData(
            this.offer.adobeConfiguration.vehicleTrim === '' ? null : this.offer.adobeConfiguration.vehicleTrim,
            this.offer.adobeConfiguration.vehicleModel === '' ? null : this.offer.adobeConfiguration.vehicleModel,
            this.offer.adobeConfiguration.vehicleID === '' ? null : this.offer.adobeConfiguration.vehicleID,
          );
        } else {
          this.adobeService.pageLoadOffer(null, null, null);
          this.adobeThankyouPageData = this.adobeService.getThankyouPageOfferData(null, null, null);
        }

        this.blueKaiService.changeRoute(this.offer.model.group.name, (this.offer.dealer !== null ? this.offer.dealer.name : 'invenduto'));

        if (!AppComponent.isDealerStock && this.offer.dealer) {
          AppComponent.dealerCode = this.offer.dealer.code;
        }

        this.financialProductsLabel = [];

        if (this.offer.financial_products.length !== 0) {
          let min_installment = -1;
          for (const item in this.offer.financial_products) {

            if (min_installment === -1) {
              min_installment = this.offer.financial_products[item].min_installment;
            }

            if (this.offer.financial_products[item].min_installment <= min_installment) {
              for (const val in this.offer.financial_products[item].parameters) {
                if (this.offer.financial_products[item].parameters[val].netto) {
                  this.financialProductsLabel['price_type'] = 'netto';
                } else {
                  this.financialProductsLabel['price_type'] = 'brutto';
                }
              }

              this.financialProductsLabel['min_installment'] = this.offer.financial_products[item].min_installment;
              this.financialProductsLabel['label'] = this.offer.financial_products[item].label;
              min_installment = this.offer.financial_products[item].min_installment;
            }
          }
        } else {
          this.financialProductsLabel = null;
        }

        if (this.offer.images.length === 0) {
          this.offer.images = [
            DefaultImagePipe.getDefaultImage(this.offer.id.toString())
          ];
        }

        if (this.offer.videos !== undefined && TrackingService.checkCookie('opncl_advertising') === 'true') {
          for (const vid of this.offer.videos) {
            this.galleryVidImg.push({
              type: 'video',
              id: vid.id,
              path: 'https://img.youtube.com/vi/' + vid.id + '/hqdefault.jpg'
            });
          }
        }

        for (const image of this.offer.images) {
          this.galleryVidImg.push({type: 'image', id: image + '?fm=pjpg', path: image + '?fm=pjpg', originUrl: image});
        }

        for (const image of this.offer.images) {
          this.galleryImages.push({path: image + '?fm=pjpg'});
        }

        if (this.offer.brand.slug === 'alfa-romeo') {
          if (this.offer.dealer.rrdi !== null) {
            this.isSol = true;
            switch (this.offer.model.group.slug) {
              case 'giulia':
                this.beamHeadline = 'Twoja Giulia';
                break;
              default:
                this.beamHeadline = 'Twoje ' + this.offer.model.group.name.toLowerCase();
            }
          } else {
            this.isSol = false;
          }
        } else if (this.offer.brand.slug === 'jeep') {
          if (this.offer.dealer.rrdi !== null) {
            this.isSol = true;
          } else {
            this.isSol = false;
          }
          this.beamHeadline = 'Twój ' + this.offer.model.group.name;
        } else if (environment.isSol && this.offer.brand.slug === 'fiat') {
          if (this.offer.dealer.rrdi !== null) {
            this.isSol = true;
            this.beamHeadline = 'Twój Fiat ' + this.offer.model.group.name;
            $('.header--nav-list').addClass('nav-list-sol');
          } else {
            this.isSol = false;
          }
        } else if (environment.isSol && this.offer.brand.slug === 'fiat-professional') {
          if (this.offer.dealer.rrdi !== null) {
            this.isSol = true;
            switch (this.offer.model.group.slug) {
              case 'nowa-panda-van':
                this.beamHeadline = 'Twoja ' + this.offer.model.group.name;
                break;
              default:
                this.beamHeadline = 'Twoje ' + this.offer.model.group.name;
            }
            $('.header--nav-list').addClass('nav-list-sol');
          } else {
            this.isSol = false;
          }
        } else {
          this.isSol = false;
        }

        if (this.offer.brand.slug !== 'abarth' &&
          (this.offer.technicalData.fuelType === 'Elektryczne' || this.offer.technicalData.fuelType === 'BEV')) {
          this.optionalInsuranceForBev = true;
        } else {
          this.optionalInsuranceForBev = false;
        }

        const galleryOpenVideoOrImage = (type, id, path) => {
          const cgWrapper = document.querySelector('.cg-wrapper');
          if (type === 'video') {
            const host = location.protocol + '//' + location.host;
            cgWrapper.innerHTML = '<iframe id="ytplayer" style="z-index:5000; margin:0 60px;" type="text/html" ' +
              'src="https://www.youtube.com/embed/' + id + '?enablejsapi=1&rel=0&origin=' + host + '" frameborder="0"/>';
            this.onYouTubeIframeAPIReady();
          } else {
            cgWrapper.innerHTML = '<img src="' + path + '"></div>';
          }
        };

        CrystalLightbox.prototype.open = function (images, config = {}) {

          config = this.applyConfigDefaults({
            masonry: true,
            masonryMaxHeight: 200,
            masonryGutter: 4,
            loop: false,
            index: 0,
            backgroundOpacity: 0.85,
            counter: false,
            lightboxMaxHeight: '100%',
            lightboxMaxWidth: '100%',
            animationDuration: 100
          }, config);

          const data = {
            images,
            config
          };

          const component = LightboxComponent;

          this.appendComponentToBody(component, data);

          galleryOpenVideoOrImage(images[config.index].type, images[config.index].id, images[config.index].path);

        };

        LightboxComponent.prototype.next = function() {
          this.currentImageIndex++;

          if (this.currentImageIndex >= this.data.images.length ) {
            this.currentImageIndex = 0;
          }

          galleryOpenVideoOrImage(
              this.data.images[this.currentImageIndex].type,
              this.data.images[this.currentImageIndex].id,
              this.data.images[this.currentImageIndex].path
          );
        };

        LightboxComponent.prototype.prev = function() {
          this.currentImageIndex--;

          if (this.currentImageIndex < 0 ) {
            this.currentImageIndex = this.data.images.length - 1;
          }

          galleryOpenVideoOrImage(
              this.data.images[this.currentImageIndex].type,
              this.data.images[this.currentImageIndex].id,
              this.data.images[this.currentImageIndex].path
          );
        };

        // Włączenie wyboru typu klienta
        if ((this.brandSlug === 'jeep' ||
            this.brandSlug === 'alfa-romeo' ||
            (environment.isSol && this.brandSlug === 'fiat') ||
            (environment.isSol && this.brandSlug === 'fiat-professional') ) && this.offerFinancialCalculators['b2b'] !== undefined) {
          this.customerTab = true;

          if (QSS.getParam('customerType') === null) {
            this.activeCustomer = 'b2b';
          } else {
            switch (QSS.getParam('customerType')) {
              case 'b2c':
                this.activeCustomer = 'b2c';
                break;
              default:
                this.activeCustomer = 'b2b';
            }
          }
        } else {
          this.customerTab = false;
        }

        setTimeout(() => {
          this.loaded = true;
          window.dispatchEvent(new Event('resize'));
        }, 500);

        setTimeout(() => {
          $('.hidden-box').each((key, el) => {
            if ($(el).height() < 320) {
              $(el).addClass('hiddenMoreLessLinks');
              this.hiddenText[$(el).data('opt')] = false;
            } else if ($(el).data('opt') === 'technicalData' && $(window).width() >= 980) {
              $(el).addClass('hiddenMoreLessLinks');
            }
          });
        }, 300);

        // if (this.offer.dealer) {
        //   OfferComponent.freespeeMasterkeyData = this.getFreeSpeeData();
        // }

        this.bannerService.selectBanner(this.offer.model.group.slug, true).then(selectedBanner => {
          BannerComponent.onSelectBanner.emit(selectedBanner);
        });

        this.solCounter = 0;
        this.solCounterText = '';

        if (this.siteData.sol_promo_offers_count !== undefined) {
          if (this.siteData.sol_promo_offers_count > 0) {

            if (this.brandSlug === 'alfa-romeo') {
              this.promoAR_oc_1 = true;
            } else if (this.brandSlug === 'fiat-professional') {
              this.solCounter = this.siteData.sol_promo_offers_count;

              if (this.solCounter === 1) {
                this.solCounterText = 'Ostatnia ' + this.solCounter + ' sztuka w ofercie!';
              } else if (this.solCounter  > 1 && this.solCounter < 5 ) {
                this.solCounterText = 'Ostatnie ' + this.solCounter + ' sztuki w ofercie!';
              } else {
                this.solCounterText = 'Ostatnie ' + this.solCounter + ' sztuk w ofercie!';
              }
            }
          } else {
            this.promoAR_oc_1 = false;
          }
        } else {
          this.promoAR_oc_1 = false;
        }

        this.brandConfig = BrandService.getConfig();

        if (this.isSol) {
          setTimeout(() => {
            AppComponent.bodyClass = 'page page-offert page-offer-sol';
          });
          this.setInsuranceSol();
        }

        TawkToChatService.enableTawkToChat(this.offer.dealer.code, true);
      }).catch(reason => {
        ErrorHandler.log(reason);
        this.offerNotExist = true;
        this.loaded = true;
      });
      this.stockApi.getSimilarOffers(
        +params['id'],
        this.appComponentReference.isDealerStock ? this.appComponentReference.dealerCode : null
      ).then(value => {
        value = this.labelProviderService.fillOfferLabelsByArray(value);
        this.similarOffers = value;
      }).catch(reason => {
        ErrorHandler.log(reason);
      });
    });

    this.showVin = QSS.getParam('showVin') !== null || QSS.getParam('showvin') !== null || QSS.getParam('ShowVin') !== null;

    $('body').on( 'touchstart', (e) => {
      if ($('.cg-wrapper').is(':visible')) {
        this.mouseDownPositionX = e.originalEvent.touches[0].clientX;
      }
    });

    $('body').on('touchmove', (e) => {
      if ($('.cg-wrapper').is(':visible')) {
        this.mouseUpPositionX = e.originalEvent.touches[0].clientX;
      }
    });

    $('body').on('touchend', () => {
      if ($('.cg-wrapper').is(':visible') &&
        this.mouseDownPositionX !== this.mouseUpPositionX &&
        this.mouseUpPositionX !== -1) {
        if (this.mouseDownPositionX - this.mouseUpPositionX > 0) {
          $('.cg-next').click();
        } else {
          $('.cg-prev').click();
        }
        this.mouseUpPositionX = -1;
      }
    });
  }


  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page page-offert ';
    });

    this.lastFindProperties = this.LSS.get('lfprop', {});
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  ngOnDestroy(): void {
    TawkToChatService.disableTawkToChat();
    this.idParamSubscription.unsubscribe();

    if (!AppComponent.isDealerStock) {
      AppComponent.dealerCode = null;
      $('#livechat-compact-container').hide(0);
      $('#livechat-eye-catcher').hide(0);
    }

    const bodyElement = $('body');
    if (bodyElement.hasClass('form-modal-open')) {
      bodyElement.removeClass('form-modal-open');
    }

    if (environment.isSol && this.offer.dealer.rrdi !== null &&
      (this.offer.brand.slug === 'fiat' || this.offer.brand.slug === 'fiat-professional')) {
      $('.header--nav-list').removeClass('nav-list-sol');
    }
  }

  selectIndex(index) {
    this.index = index;
  }

  scrollToForm = () => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.form--offert').offset().top - 130
    }, 1000);
  }

  scrollToLeasingCalculator = () => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.financing--sol-tab').offset().top - 180
    }, 1000);
  }

  scrollToCommunicators = () => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.communicators-link').offset().top - 130
    }, 1000);
  }

  scrollToFinancialProducts = () => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $('.financial-products').offset().top - 130
    }, 700);
  }

  share() {
    ShareComponent.openModal();
    this.sendGA('send', 'event', 'link', 'click', 'share');
  }

  hasLastFindProperties(): boolean {
    return Object.keys(this.lastFindProperties).length > 0;
  }
  formatNumber(number): string {
    number = number.substr(number.length - 9);
    return '+48&#8201;' + number[0] + number[1] + '&#8201;' + number[2] + number[3] + number[4] + '&#8201;' + number[5] + number[6] + '&#8201;' + number[7] + number[8];
  }

  showPhone(phone = null) {
    const tracking = async () => {
      this.blueKaiService.click2call();
      this.sendGA('send', 'event', 'link', 'click', 'showPhoneNumber');

      if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.SIZMEK) &&
        TrackingService.isOpenclPerformanceCookieEnabled()) {
        switch (this.offer.brand.code) {
          case '00':
            this.sendSizmek('1147896');
            break;
          case '77':
            this.sendSizmek('1455114');
            break;
          case '83':
            this.sendSizmek('1455113');
            break;
          case '57':
            this.sendSizmek('1455115');
            break;
          case '66':
            this.sendSizmek('1457909');
            break;
        }
      }

      if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.GTM) &&
        TrackingService.isOpenclPerformanceCookieEnabled()) {
        switch (this.offer.brand.code) {
          case '00':
            this.sendGtm('10176142', 'fiatnume', 'plfianum');
            break;
          case '77':
            this.sendGtm('10176142', 'lcvnume', 'pllcvnum');
            break;
          case '83':
            this.sendGtm('10176142', 'alfanume', 'plalfnum');
            break;
          case '57':
            this.sendGtm('10176142', 'jeepnume', 'pljeepnu');
            break;
          case '66':
            this.sendGtm('10176142', 'pokaznum', 'abartnum');
            break;
        }
      }
    };

    if (!phone && this.offer && this.offer.dealer && this.offer.dealer.phones && this.offer.dealer.phones[0]) {
      phone = this.offer.dealer.phones[0];
    }
    if (phone) {
      this.freespeeDealerPhoneNumber = phone;
      this.changeDetectorRef.detectChanges();
      tracking();
    //   window.__fs_dncs_instance.getNumber(
    //     (ref, res) => {
    //       this.freespeeDealerPhoneNumber = '+48' + res.e164.substr(res.e164.length - 9);
    //       this.changeDetectorRef.detectChanges();
    //       tracking();
    //     },
    //     null,
    //     phone.substr(phone.length - 9),
    //     'fcausedportalpl',
    //     phone.substr(phone.length - 4).split('').reduce((a, b) => +a + +b, 0),
    //     '&' + jQuery.param(this.getFreeSpeeData()),
    //     null
    //   );
    // } else {
    //   throwError('Dealer phone not found!');
    }
  }

  sendSizmek(activityID) {
    const cookieSessionId = this.getCookieSessionId();
    if (cookieSessionId !== 'false') {
      const ebSession = cookieSessionId;
      let ebRand: any = Math.random() + '';
      ebRand = ebRand * 1000000;

      const newScript = document.createElement('script');
      newScript.type = 'text/javascript';
      newScript.src = 'https://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&ActivityID=' + activityID + '&rnd='
          + ebRand + '&Session=' + ebSession;
      document.body.appendChild(newScript);
    }
  }

  sendGtm(activityID, type, cat) {
    if ($('.iframe-gtm').length) {
      $( '.iframe-gtm' ).remove();
    }

    const axel: any  = Math.random() + '';
    const a = axel * 10000000000000;
    const newIframe = document.createElement('iframe');
    newIframe.height = '1';
    newIframe.width = '1';
    newIframe.style.display = 'none';
    newIframe.className = 'iframe-gtm';

    newIframe.src = 'https://' + activityID + '.fls.doubleclick.net/activityi;src=' + activityID + ';type=' + type + ';cat=' + cat +
      ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?;';
    document.body.appendChild(newIframe);

  }


  getCookieSessionId(): string {
    const name = 'PHPSESSID';
    const nameLenPlus = (name.length + 1);
    const value = document.cookie
        .split(';')
        .map(c => c.trim())
        .filter(cookie => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map(cookie => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null;
    if (value !== 'false') {
      return value;
    } else {
      return 'false';
    }
  }

  onYouTubeIframeAPIReady() {
    this.player = new window.YT.Player( 'ytplayer', {
      events: { 'onStateChange': this.onPlayerStateChange }
    });
  }

  onPlayerStateChange(event) {
    if (event.data === 0) {
      event.target.stopVideo();
    }
  }
  setDefaultImg140x76(event) {
    event.target.src = '/assets/img/default_img_140x76.png';
  }
  setDefaultImg800x600(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_800x600.png" class="box-model__photo-img" />';
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  sendGA(event: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: string) {
    if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS)) {
      if (typeof ga !== 'undefined') {
        ga(event, eventCategory, eventAction, eventLabel, eventValue);
      }
      if (typeof gtag !== 'undefined') {
          gtag(eventCategory, eventLabel, {
            'event_category': eventAction,
            'event_label': eventValue
          });
      }
    }
  }

  getFreeSpeeData(): object {
    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
      }
      if (
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return 'mobile';
      }
      return 'desktop';
    };

    let MASTERKEYType_Codbrand = this.offer.brand.name.toUpperCase();

    if (this.offer.brand.code === '83') {
      MASTERKEYType_Codbrand = 'ALFAROMEO';
    } else if (this.offer.brand.code === '77') {
      MASTERKEYType_Codbrand = 'FIATPRO';
    }


    if (!this.siteData.freespee_data.campaign_name
      || !this.siteData.freespee_data.campaign_id
      || !this.siteData.freespee_data.offer_id
      || !this.siteData.freespee_data.level1
      || !this.siteData.freespee_data.level2
      || !this.siteData.freespee_data.level3
      || !this.siteData.freespee_data.level4
      || !this.siteData.freespee_data.start_date
      || !this.siteData.freespee_data.end_date
      || !this.siteData.freespee_data.source
    ) {
      Raven.setUserContext({
        'freespee_data': this.siteData.freespee_data,
        'page_url': location.href
      });
      Raven.captureException(new Error('Phone MK data not provided or incomplete'));
      console.error('Phone MK data not provided or incomplete');
    }

    return {
      custnr: this.offer.dealer.code + '.' + this.offer.dealer.sitecode,
      custname: this.offer.dealer.name.substr(0, 60),
      MandateCode: this.offer.dealer.code,
      OutletCode: this.offer.dealer.sitecode,
      MasterKey: this.siteData.freespee_data.campaign_name + ' - SALES',
      MASTERKEYType_Codbrand,
      MASTERKEYType_Codmarket: '3123',
      MASTERKEYType_Language: 'D',
      MASTERKEYType_Offer: this.siteData.freespee_data.offer_id || '209', // Domyślny OID '209', wartość wymagana przez Link [FCASTOCK3-410]
      MASTERKEYType: 'PH',
      MASTERKEYType_Campaign: this.siteData.freespee_data.campaign_id,
      MASTERKEYType_Level1: this.siteData.freespee_data.level1,
      MASTERKEYType_Level2: this.siteData.freespee_data.level2,
      MASTERKEYType_Level3: this.siteData.freespee_data.level3,
      MASTERKEYType_Level4: this.siteData.freespee_data.level4,
      MASTERKEYType_MBA: '1',
      MASTERKEYType_Campaign_Desc: this.siteData.freespee_data.campaign_name,
      MASTERKEYType_CampaignStart: this.siteData.freespee_data.start_date,
      MASTERKEYType_CampaignEnd: this.siteData.freespee_data.end_date,
      CAMPAIGNType_Source: this.offer.brand.code === '77' ? 'PP' : 'PS',
      CAMPAIGNType: 'LX',
      CAMPAIGNDETAILType_codmarket: '3123',
      CAMPAIGNDETAILType_codbrand: this.offer.brand.code,
      CAMPAIGNDETAILType_coddealer: this.offer.dealer.code,
      CAMPAIGNDETAILType_Coddealerlocation: this.offer.dealer.sitecode,
      CAMPAIGNDETAILType_Language: 'D',
      MK_CampaignSource: this.siteData.freespee_data.source,
      PRODUCTType_CODMODEL: this.offer.model.code,
      PRODUCTType_DESCRIPTION: this.offer.brand.name + ' ' + this.offer.model.name,
      PRODUCTType_BRAND: this.offer.brand.name,
      PRODUCTType_VIN: this.offer.vin,
      PRODUCTType_CONDITION: 'new',
      PROMOType_LASTREFERRER: (TrackingService.isOpenclPerformanceCookieEnabled() ? this.siteData.freespee_data.referer : ''),
      PROMOType_DEVICEUSED: getDeviceType(),
      PROMOType_PAGEURL: btoa(location.href),
    };
  }

  checkBusinessLink() {
    switch (this.brandSlug) {
      case 'jeep':
        return 'https://www.jeep.pl/biznes';
      case 'alfa-romeo':
        return 'https://www.alfaromeo.pl/klient-biznesowy';
      default:
        return '';
    }
  }

  showComunicators(){
    if (['fiat', 'fiat-professional', 'abarth'].indexOf(this.brandSlug) > -1) {
      let show = false;
      for (const item in this.offer.dealer.communicators) {
        if (this.offer.dealer.communicators[item].name === 'Messenger' || this.offer.dealer.communicators[item].name === 'WhatsApp' ) {
          show = true;
        }
      }
      return show;
    } else {
      return true;
    }
  }

  showLabelClass() {
    for (const label of this.offer.labels) {
      if (label === 'Najniższa cena') {
        this.labelClassName.lowestPrice = true;
      } else if (label === 'Największy rabat') {
        this.labelClassName.highestDiscount = true;
      }
    }
  }

  initMap(lat, lng) {
    setTimeout(() => {
      const uluru = { lat, lng };
      const map = new google.maps.Map(document.getElementById('map'), {
        zoom: 12,
        center: uluru,
      });

      const marker = new google.maps.Marker({
        position: uluru,
        map: map,
      });
    }, 200);
  }

  selectFinancialCalculator(customer, key, financingSlug) {
    this.activeFinancialCalculatorId[customer] = financingSlug;
    if (customer === 'b2c' && financingSlug.includes('p0p')) {
      this.infoFormUrl = 1;
    } else {
      this.infoFormUrl = null;
    }
  }

  selectCustomer(customer) {
    this.activeCustomer = customer;
    if (customer === 'b2c' && this.activeFinancialCalculatorId.b2c && this.activeFinancialCalculatorId.b2c.includes('p0p')) {
      this.infoFormUrl = 1;
    } else {
      this.infoFormUrl = null;
    }
    if (customer === 'b2c') {
      this.selectedPriceType = 'GROSS';
    } else {
      this.selectedPriceType = 'NET';
    }
  }

  setValue(data: any) {
    if (data.key === 'all' || this.customerTab === false) {
      this.activeFinancialCalculatorId[data.key] = data.name;
      return;
    }

    let periodLabel = null;
    const value = data.data,
      firstFinancingId = this.offerFinancialCalculators[data.key][0].name;
    periodLabel = value.calculation.currentFields.period.value - 1;

    if (this.wasSol === null) {
      this.wasSol = this.isSol;
    }

    if (value.calculation.currentFields.period.value === 24) {
      periodLabel += ' miesiące';
    } else {
      periodLabel += ' miesięcy';
    }

    // Włączenie pierwszego kalkulatora
    if (this.activeFinancialCalculatorId[data.key] === null && firstFinancingId === data.name) {
      this.activeFinancialCalculatorId[data.key] = data.name;

      if (this.selectedPriceType === '') {
        this.selectedPriceType = value.calculation.priceType;
      }
    }

    if (this.activeCustomer === 'b2b' && this.offerFinancialCalculators['b2b'].length === 0) {
      this.activeCustomer = 'b2c';
    }

    if (this.activeCustomer === 'b2c' && this.offerFinancialCalculators['b2c'].length === 0) {
      this.activeCustomer = 'b2b';
    }

    if (this.offerFinancialCalculators['b2c'].length === 0 && this.offerFinancialCalculators['b2b'].length === 0) {
      this.isSol = false;
    } else {
      this.isSol = this.wasSol;
    }

    // Włączenie informacji o Flex
    if (data.key === 'b2c' && data.name === 'b2c') {
      if (value.calculation.availableExtraServices.length > 0) {
        this.serviceFlex = true;
      }
    }

    this.availableFinancialCalculators[data.key][data.name] = {
      offerId: this.offer.id,
      calculatorId: value.offerId,
      calculatorSlug: data.name,
      installment: Math.ceil(value.calculation.installment),
      period: value.calculation.currentFields.period.value,
      periodLabel: periodLabel,
      priceType: value.calculation.priceType === 'GROSS' ? 'brutto' : 'netto',
      calculation: value.calculation,
      correctInstallment: !( // FCAJFIN-24 Ograniczenie na kwotę (dotyczy tylko Abonamentów)
        (value.calculation.clientType === 'b2c' || value.calculation.clientType === 'b2b') && value.calculation.installment <= 150)
    };
  }

  setOfferFinancialCalculators(customerType, calculatorType, calculation: any) {
    this.offerFinancialCalculators[customerType].push({
      name: calculatorType,
      calculation: calculation[calculatorType]
    });
    this.setValue({
      data: {
        calculation: calculation[calculatorType],
        offerId: 'calculate_' + this.offer.id
      },
      key: customerType,
      name: calculatorType
    });
  }

  setOrderCalculator(orderList: Array<string>, calculation: any) {
    for (const calculatorType of orderList) {
      if (calculation[calculatorType] !== null) {
        const customerType = calculatorType === 'b2b' || calculatorType === 'l101' ? 'b2b' : 'b2c';
        this.setOfferFinancialCalculators(customerType, calculatorType, calculation);
      }
    }
  }

  openOrCloseForm(open) {
    if (open) {
      $('body').addClass('form-modal-open');
    } else {
      $('body').removeClass('form-modal-open');
    }
    this.modalFormIsOpen = !this.modalFormIsOpen;
  }

  showExtraServicesInfo () {
    this.showModalFlex = true;
    setTimeout(() => {
      $('body').addClass('form-modal-open');
      $('.modal-flex').fadeIn( 'fast');
    }, 100);
  }

  goToCart() {
    const tmpData = this.availableFinancialCalculators[this.activeCustomer][this.activeFinancialCalculatorId[this.activeCustomer]];
    const data = {
      offerId: tmpData.offerId,
      calculatorSlug: tmpData.calculatorSlug,
      calculatorId: tmpData.calculatorId,
      calculation: tmpData.calculation,
      selectedPriceType: this.selectedPriceType
    };
    this.LSS.set(OfferComponent.STORAGE_SOL_KEY, data);

    const stockUrl = BrandService.getCurrentBrand().stockUrl;
    const prefix = stockUrl.split('/').slice(3).join('/');
    if (prefix.length > 0) {
      this.router.navigate(['/' + prefix + '/koszyk']);
    } else {
      this.router.navigate(['/koszyk']);
    }
  }

  showTooltip($event) {
    const el = $($event.target);
    el.addClass('show-tooltip');
  }

  hideTooltip($event) {
    const el = $($event.target);
    el.removeClass('show-tooltip');
  }

  hideTooltipClick($event) {
    const el = $($event.target);
    el.parent().removeClass('show-tooltip');
  }

  openSolDescription(id) {
    const elem = $('.' + id);
    if (elem.hasClass('sol-accordion-box--is-open')) {
      elem.removeClass('sol-accordion-box--is-open');
      elem.find('.sol-accordion-box--description').slideUp( 'fast');
    } else {
      elem.addClass('sol-accordion-box--is-open');
      elem.find('.sol-accordion-box--description').slideDown( 'fast');
    }
  }

  openSolFaqDescription(id) {
    const elem = $('.' + id);
    if (elem.hasClass('sol-accordion-box-faq--is-open')) {
      elem.removeClass('sol-accordion-box-faq--is-open');
      elem.find('.sol-accordion-box--description-faq').slideUp( 'fast');
    } else {
      elem.addClass('sol-accordion-box-faq--is-open');
      elem.addClass('sol-accordion-box-faq--is-open');
      elem.find('.sol-accordion-box--description-faq').slideDown( 'fast');
    }
  }

  getInfoForm() {
    const tmpData = this.availableFinancialCalculators[this.activeCustomer][this.activeFinancialCalculatorId[this.activeCustomer]];
    if (tmpData && this.activeCustomer === 'b2c') {
      const data = {};
      let baseNetPrice, baseGrossPrice;
      if (this.offer.price.shipping_fee) {
        if (this.offer.brand.slug === 'fiat-professional') {
          baseNetPrice = this.offer.price.base.netto + this.offer.price.shipping_fee;
          baseGrossPrice = this.offer.price.base.brutto + Math.round(this.offer.price.shipping_fee * 1.23);
        } else {
          baseNetPrice = this.offer.price.base.netto + Math.round(this.offer.price.shipping_fee / 1.23);
          baseGrossPrice = this.offer.price.base.brutto + this.offer.price.shipping_fee;
        }
      } else {
        baseNetPrice = this.offer.price.base.netto;
        baseGrossPrice = this.offer.price.base.brutto;
      }

      data[tmpData.offerId] = {
        'vehicle': {
          'modelName': this.offer.model.name,
          'modelCode': this.offer.model.commercialCode,
          'versionName': this.offer.version.name,
          'productionYear': this.offer.productionYear,
          'grossPrice': this.offer.price.final.brutto,
          'netPrice': this.offer.price.final.netto,
          'baseGrossPrice': baseGrossPrice,
          'baseNetPrice': baseNetPrice,
          'versionId': this.offer.sincom,
          'fuelType': this.offer.technicalData.fuelType,
          'status': this.offer.is_km0 ? 'km0' : 'new'
        },
        'fields': this.getFieldValuesForCalculation(tmpData.calculation.currentFields),
        'dealer': this.offer.dealer
      };

      return environment.financialCalculations.apiUrl + 'api/calculation/generate-information-form/' + this.brandSlug +
        '/' + tmpData.calculation.calculatorId + '/'  + tmpData.calculatorSlug +
        '?deflated_data=' + this.deflateRawData(data);
    } else {
      return '';
    }
  }

  deflateRawData(data) {
    const jsonString = JSON.stringify(data);
    const pakoString = deflateRaw(jsonString, { to: 'string' });
    return encodeURIComponent(btoa(pakoString));
  }

  getFieldValuesForCalculation(sliders) {
    let result = {};
    for (const key of Object.keys(sliders)) {
      result[key] = sliders[key].value;
    }

    return result;
  }

  setInsuranceSol() {
    // FCASTOCK3-586 Z programu wyłączony jest model Renegade 1.0 Longitude MT. Dla tego modelu ubezpieczenie powinno być
    // w wartości 2,7% ceny brutto.
    // Oferta nie dotyczy km0
    if (this.offer.is_km0 === false) {
      const noPromoSol = ['57619M251000', '57619P251000', '57619A251000', '57619M2K1000', '57619P2K1000', '57619A2K1000']; // FCASTOCK3-630
      if (this.offer.brand.code === '57' &&
        (
          (this.offer.technicalData.engineType === 'FireFly Turbo 1.0' || this.offer.technicalData.engineType === '1.0 GSE T3 120KM') &&
          this.offer.model.code === '609' &&
          this.offer.version.commercialCode === 'Longitude' && this.offer.technicalData.gearboxType === 'Manualna' &&
          this.offer.technicalData.fuelType === 'Benzyna') ||
          (noPromoSol.indexOf(this.offer.sincom) !== -1) // FCASTOCK3-630
      ) {
        this.insuranceSol = null;
        this.insuranceSolPercent = null;
      } else if (this.offer.brand.code === '00') {
        this.insuranceSol = null;
        this.insuranceSolPercent = null;
      } else if (this.offer.brand.code === '77') {
        this.insuranceSol = null;
        this.insuranceSolPercent = null;
        this.packageService = false;
        // if (this.isPromoPackageService()) {
        //   this.packageService = true;
        // } else {
        //   this.packageService = false;
        // }
        //
        // if (this.isPromoInsurance()) {
        //   this.insuranceSol = Math.ceil(this.offer.price.final.brutto * 0.0099);
        //   this.insuranceSolPercent = '0,99%';
        // } else {
        //   this.insuranceSol = null;
        //   this.insuranceSolPercent = null;
        // }
      } else if (this.offer.brand.code === '83') {
        if (this.promoAR_oc_1) {
          this.insuranceSol = Math.ceil(this.offer.price.final.brutto * 0.01);
          this.insuranceSolPercent = '1%';
        } else {
          this.insuranceSol = null;
          this.insuranceSolPercent = null;
        }
        // this.additionalProtection = true;
      } else {
        // this.insuranceSol = Math.ceil(this.offer.price.final.brutto * 0.017);
        // this.insuranceSolPercent = '1,7%';
        this.insuranceSol = null;
        this.insuranceSolPercent = null;
      }
    // } else {
    //   if (this.offer.brand.code === '57') {
    //     this.insuranceSol = Math.ceil(this.offer.price.final.brutto * 0.017);
    //     this.insuranceSolPercent = '1,7%';
    //   } else if (this.offer.brand.code === '83') {
    //   if (this.offer.brand.code === '83') {
    //     if (this.offer.labels.indexOf('FCASTOCK3-650-PROMO') !== -1) {
    //       this.additionalProtection = true;
    //     } else {
    //       this.additionalProtection = false;
    //     }
    //   }
    }
  }

  isPromoPackageService() { // Pakiet Serwisowy Pełna Obsługa na 3 lata /180 000km
    const sincom = this.offer.sincom;
    if (sincom.length > 9) {
      const code = sincom.slice(2, 5);
      const series = sincom.slice(8, 9);

      switch (code) {
        case '225':
          return true;
        case '519':
          return true;
        case '510':
          return series === '1' || series === '0';
        case '511':
          return series === '1' || series === '0';
        case '506':
          return series === '1' || series === '0';
        case '507':
          return series === '1' || series === '0';
        case '290':
          return series === '9';
        case '295':
          return series === '9';
        case '505':
          return series === '7';
      }
    } else {
      return false;
    }
  }
  isPromoInsurance() {
    const sincom = this.offer.sincom;
    if (sincom.length > 9) {
      const code = sincom.slice(2, 5);
      const series = sincom.slice(8, 9);

      switch (code) {
        case '510':
          return series === '1' || series === '0';
        case '511':
          return series === '1' || series === '0';
        case '506':
          return series === '1' || series === '0';
        case '507':
          return series === '1' || series === '0';
        case '290':
          return series === '9';
        case '295':
          return series === '9';
        case '505':
          return series === '7';
      }
    } else {
      return false;
    }
  }

  setSelectedPriceType(data: any) {
    this.selectedPriceType = data.priceType;
  }
}
